export const COMMUNITY_USER_TAG_TOOLBOX_FRAGMENT = `
  fragment communityUserTagToolboxFragment on CommunityUserTag {
    uid
    name
    _linkCount
    links {
      uid
      targetType
      target {
        uid
        __typename
        ... on Exhibitor {
          name
          logoFileResource {
            path
            schemaCode
          }
        }
        ... on SubEdition {
          name
          code
          logoFileResource {
            path
            schemaCode
          }
        }
        ... on Product {
          name
          images(first: 1) {
            fullFileResource {
              path
              schemaCode
            }
          }
        }
        ... on LargeProduct {
          name
          images(first: 1) {
            fullFileResource {
              path
              schemaCode
            }
          }
        }
        ... on Deal {
          name
          displayFileResource {
            path
            schemaCode
          }
        }
        ... on Session {
          name
          imageFileResource {
            path
            schemaCode
          }
        }
        ... on Speaker {
          firstName
          lastName
          prefix
          suffix
          photoFileResource {
            path
            schemaCode
          }
        }
        ... on CommunityUser {
          firstName
          lastName
          prefix
          suffix
          pictureFileResource {
            path
            schemaCode
          }
        }
      }
    }
  }
`;
